import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB2-U4-P33-E1',
    audio: 'img/FriendsPlus/Page33/E1/Audio/audio.e1.p33.mp3',
    video: '',
    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page33/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page33/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page33/E1/Audio/tieude.e1.p33.mp3' },
        { url: 'img/FriendsPlus/Page33/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page33/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page33/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page33/E1/Audio/17.mp3' },
        { url: 'img/FriendsPlus/Page33/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page33/E1/Audio/18.mp3' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB2-U4-P33-E2',
    audio: 'img/FriendsPlus/Page33/E2/Audio/sing.e2.p33.mp3',
    video: '',
    component: UnderLine,
    isAllowSubmit: true,
    songLyric:
      "<p>Look at the bikes. </p>" +
      "<p>Fast and slow. </p>" +
      "<p>How many bikes? </p>" +
      "<p>Watch them go. </p>" +
      "<p>1, 2, 3, 4, 5 </p>" +
      "<p>6, 7, 8, 9, 10 </p>" +
      "<p>11, 12, 13, 14, 15 </p>" +
      "<p>16, 17 </p>" +
      "<p>Seventeen bikes. </p>" +
      "<p>Fast and slow. </p>" +
      "<p>Look at the boats. </p>" +
      "<p>Fast and slow. </p>" +
      "<p>How many boats? </p>" +
      "<p>Watch them go. </p>" +
      "<p>1, 2, 3, 4, 5 </p>" +
      "<p>6, 7, 8, 9, 10 </p>" +
      "<p>11, 12, 13, 14, 15 </p>" +
      "<p>16, 17, 18 </p>" +
      "<p>Eighteen boats </p>" +
      "<p>Fast and slow.</p>",
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page33/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page33/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page33/E2/Audio/tieude.e2.p33.mp3' },
        { url: 'img/FriendsPlus/Page33/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page33/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page33/E1/Audio/17.mp3' },
        { url: 'img/FriendsPlus/Page33/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page33/E1/Audio/18.mp3' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB2-U4-P33-E3',
    audio: '',
    video: '',
    component: UnderLine,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page33/E3/1.jpg' },
      ],

    ],
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB2-U4-P33-E4',
    audio: '',
    video: '',
    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page33/E4/1.jpg' },

      ],

    ],
  },

}

export default json;
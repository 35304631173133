import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
    1: { // Exercise num
      unit : 'Unit 6',
      id : 'SB2-U6-P42-E1',
        audio: 'img/FriendsPlus/Page42/E1/Audio/audio.e1.p42.mp3',
        // video: 'Videos/P15E1.mp4',
        component: UnderLine,
        isAllowSubmit: true,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page42/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page42/E1/2.jpg',audioUrl: 'img/FriendsPlus/Page42/E1/Audio/tieude.e1.p42.mp3' },
                { url: 'img/FriendsPlus/Page42/E1/3.jpg',},
               
               
            ],
        
            [
                { url: 'img/FriendsPlus/Page42/E1/4.jpg',audioUrl: 'img/FriendsPlus/Page42/E1/Audio/kitchen.mp3' },
                { url: 'img/FriendsPlus/Page42/E1/5.jpg',audioUrl: 'img/FriendsPlus/Page42/E1/Audio/living room.mp3' },
                { url: 'img/FriendsPlus/Page42/E1/6.jpg',audioUrl: 'img/FriendsPlus/Page42/E1/Audio/dining room.mp3' },
                { url: 'img/FriendsPlus/Page42/E1/7.jpg',audioUrl: 'img/FriendsPlus/Page42/E1/Audio/bedroom.mp3'},
                { url: 'img/FriendsPlus/Page42/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page42/E1/Audio/bathroom.mp3'},
            ],
        ],
    },
    2: { // Exercise num
      unit : 'Unit 6',
      id : 'SB2-U6-P42-E2',
        audio: 'img/FriendsPlus/Page42/E2/Audio/audio.e2.p42.mp3',
        video: '',
        isAllowSubmit: true,
        component: UnderLine,
        songLyric:
          "<p>kitchen, kitchen, kitchen </p>" +
          "<p>living room, living room, living room </p>" +
          "<p>dining room, dining room, dining room </p>" +
          "<p>bedroom, bedroom, bedroom </p>" +
          "<p>bathroom, bathroom, bathroom </p>" ,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
          [
            { url: 'img/FriendsPlus/Page42/E2/1.jpg' },
            { url: 'img/FriendsPlus/Page42/E2/2.jpg',audioUrl: 'img/FriendsPlus/Page42/E2/Audio/tieude.e2.p42.mp3' },
            { url: 'img/FriendsPlus/Page42/E2/3.jpg',},

        ],
        [
            { url: 'img/FriendsPlus/Page42/E1/4.jpg',audioUrl: 'img/FriendsPlus/Page42/E1/Audio/kitchen.mp3' },
            { url: 'img/FriendsPlus/Page42/E1/5.jpg',audioUrl: 'img/FriendsPlus/Page42/E1/Audio/living room.mp3' },
            { url: 'img/FriendsPlus/Page42/E1/6.jpg',audioUrl: 'img/FriendsPlus/Page42/E1/Audio/dining room.mp3' },
            { url: 'img/FriendsPlus/Page42/E1/7.jpg',audioUrl: 'img/FriendsPlus/Page42/E1/Audio/bedroom.mp3'},
            { url: 'img/FriendsPlus/Page42/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page42/E1/Audio/bathroom.mp3'},
        ],
        ],
    },
    3: { // Exercise num
      unit : 'Unit 6',
      id : 'SB2-U6-P42-E3',
        audio: '',
        video: '',
        component: UnderLine,
        isAllowSubmit: true,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page42/E3/1.jpg'}  
            ],
            [  
                { url: 'img/FriendsPlus/Page42/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page42/E3/3.jpg',input:1,audioUrl: 'img/FriendsPlus/Page42/E1/Audio/dining room.mp3' },
                { url: 'img/FriendsPlus/Page42/E3/4.jpg' },    
            ],
            [
              { url: 'img/FriendsPlus/Page42/E3/5.jpg' },   
            ],
            [
              { url: 'img/FriendsPlus/Page42/E3/6.jpg'},
              { url: 'img/FriendsPlus/Page42/E3/7.jpg',input:2,audioUrl: 'img/FriendsPlus/Page42/E1/Audio/bathroom.mp3' },
              { url: 'img/FriendsPlus/Page42/E3/8.jpg'},
            ],
            [
             
              { url: 'img/FriendsPlus/Page42/E3/9.jpg'},
            ],
            [
              { url: 'img/FriendsPlus/Page42/E3/10.jpg'},
              { url: 'img/FriendsPlus/Page42/E3/11.jpg',input:3,audioUrl: 'img/FriendsPlus/Page42/E1/Audio/living room.mp3' },
              { url: 'img/FriendsPlus/Page42/E3/12.jpg'},
              { url: 'img/FriendsPlus/Page42/E3/13.jpg',input:4,audioUrl: 'img/FriendsPlus/Page42/E1/Audio/kitchen.mp3' },
              { url: 'img/FriendsPlus/Page42/E3/14.jpg'},
              { url: 'img/FriendsPlus/Page42/E3/15.jpg',input:5,audioUrl: 'img/FriendsPlus/Page42/E1/Audio/bedroom.mp3' },
              { url: 'img/FriendsPlus/Page42/E3/16.jpg'},
            ],
            [
             
              { url: 'img/FriendsPlus/Page42/E3/17.jpg'},
            ],
            
        ],
    },

 
  
}

export default json;
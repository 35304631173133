import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'


const json = {

  4: { // Exercise num
    unit: 'Culture',
    // unit : 'Culture',
    id: 'SB2-C-P51-E4',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page51/E4/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page51/E4/2.jpg', },
        { url: 'img/FriendsPlus/Page51/E4/3.jpg', },
        { url: 'img/FriendsPlus/Page51/E4/4.jpg', },
      ],
      [
        { url: 'img/FriendsPlus/Page51/E4/5.jpg', },
        { url: 'img/FriendsPlus/Page51/E4/6.jpg', },
        { url: 'img/FriendsPlus/Page51/E4/7.jpg', },
      ],
      // [
      //   { url: 'img/FriendsPlus/Page51/E4/2.jpg', audioUrl: 'img/FriendsPlus/Page51/Audio/Korea.mp3' },
      //   { url: 'img/FriendsPlus/Page51/E4/3.jpg', audioUrl: 'img/FriendsPlus/Page51/Audio/Switzerland.mp3' },
      //   { url: 'img/FriendsPlus/Page51/E4/4.jpg', audioUrl: 'img/FriendsPlus/Page51/Audio/the U.S.A.mp3' },
      // ],
      // [
      //   { url: 'img/FriendsPlus/Page51/E4/5.jpg', audioUrl: 'img/FriendsPlus/Page51/Audio/Thailand.mp3' },
      //   { url: 'img/FriendsPlus/Page51/E4/6.jpg', audioUrl: 'img/FriendsPlus/Page51/Audio/Viet Nam.mp3' },
      //   { url: 'img/FriendsPlus/Page51/E4/7.jpg', audioUrl: 'img/FriendsPlus/Page51/Audio/the U.S.A.mp3' },
      // ],
      [
        { url: 'img/FriendsPlus/Page51/E4/8.jpg' },
      ],

    ],
  },

  5: { // Exercise num
    unit: 'Culture',
    unit: 'Culture',
    id: 'SB2-C-P51-E5',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page51/E5/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page51/E5/2.jpg' },
      ],

    ],
  },


}
export default json;

import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB2-U3-P23-E1',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page23/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/tieude.e1.p23.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page23/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/anh1.e1.p23.mp3' },
        { url: 'img/FriendsPlus/Page23/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/anh2.e1.p23.mp3' },
        { url: 'img/FriendsPlus/Page23/E1/6.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB2-U3-P23-E2',
    audio: 'img/FriendsPlus/Page23/Audio/sing.e2.p23.mp3',
    video: '',
    songLyric:
      "<p>Pants, pants, are these his pants? </p>" +
      "<p>Yes, they are. They're his pants.  </p>" +
      "<p>Shoes, shoes, are these her shoes?  </p>" +
      "<p>No, they aren't.  </p>" +
      "<p>Her shoes are blue. </p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page23/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page23/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page23/Audio/tieude.e2.p23.mp3' },
        { url: 'img/FriendsPlus/Page23/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page23/E2/4.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB2-U3-P23-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page23/E3/1.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB2-U3-P23-E4',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page23/E4/1.jpg' },
      ],
    ],
  },
}

export default json;
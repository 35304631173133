import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB2-U6-P43-E1',
    audio: 'img/FriendsPlus/Page43/E1/Audio/audio.e1.p43.mp3',
    // video: 'Videos/P15E1.mp4',
    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page43/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page43/E1/Audio/tieude.e1.p43.mp3' },
        { url: 'img/FriendsPlus/Page43/E1/3.jpg', },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page43/E1/Audio/1.mp3' },
        { url: 'img/FriendsPlus/Page43/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page43/E1/Audio/2.mp3' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB2-U6-P43-E2',
    audio: 'img/FriendsPlus/Page43/E2/Audio/sing.e2.p43.mp3',
    video: '',
    songLyric:
      "<p>Where's Grandma? </p>" +
      "<p>Is she in the bedroom? </p>" +
      "<p>No, she isn't. </p>" +
      "<p>She's in the dining room. </p>" +
      "<p>Where's Grandpa? </p>" +
      "<p>Is he in the bathroom? </p>" +
      "<p>No, he isn't. </p>" +
      "<p>He's in the living room.</p>",
    isAllowSubmit: true,
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page43/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page43/E2/Audio/tieude.e2.p43.mp3' },
        { url: 'img/FriendsPlus/Page43/E2/3.jpg', },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E2/4.jpg' },
      ],
    ],
  },
  // 3: { // Exercise num
  unit: 'Unit 6',
  //   audio: '',
  //   video: '',
  //   component: T6,
  //   inputSize: 120,
  //   // titleImage: "img/FriendsPlus/Page44/E1/1.png",
  //   titleQuestion: [{ color: '#264598', num: '3', title: 'Look and write. Ask and answer.' }],
  //   questionImage: [],
  //   questions: [
  //     {
  //       title:
  //         "<p>1. #  Grandpa? He's # the living room.</p>" +
  //         "<p>2. Is Mom in the kitchen? # , She # .</p>",
  //       answer: [
  //         `where's`,
  //         `in`,
  //         'no',
  //         `isn't`
  //       ],
  //       type: 'longAnwser'
  //     },
  //   ]
  // },
  3: {
    id: 'SB2-U6-P43-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page43/E3/Key/answerKeyP43E3.jpg',
    fontSize: 35,
    inputHeight: '100%',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page43/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/2.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/3.jpg' },
        { url: 'img/FriendsPlus/Page43/E3/4.jpg', input: true, answer: "where's" },
        { url: 'img/FriendsPlus/Page43/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page43/E3/6.jpg', input: true, answer: "in" },
        { url: 'img/FriendsPlus/Page43/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page43/E3/10.jpg', input: true, answer: "no" },
        { url: 'img/FriendsPlus/Page43/E3/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page43/E3/14.jpg', input: true, answer: "isn't" },
        { url: 'img/FriendsPlus/Page43/E3/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/16.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB2-U6-P43-E4',
    // audio: 'img/FriendsPlus/Page43/E2/Audio/sing.e2.p43.mp3',
    video: '',
    isAllowSubmit: true,
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page43/E4/1.jpg' },
      ],
    ],
  },
}

export default json;
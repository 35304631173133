import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB2-U6-P46-E1',
    audio: 'img/FriendsPlus/Page46/E1/Audio/audio.e1.p46.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page46/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/tieude.e1.p46.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/3.jpg' },

      ],

      [
        { url: 'img/FriendsPlus/Page46/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/N.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/P.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/R.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/S.mp3' },


      ],
      [
        { url: 'img/FriendsPlus/Page46/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/nurse.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/lion.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/pen.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/11.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/lollipop.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/12.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/rabbit.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/13.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/car.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/14.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/sofa.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/15.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/octopus.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E1/16.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/T.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/17.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/V.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/18.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/X.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/19.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/Z.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E1/20.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/tiger.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/21.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/hat.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/22.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/van.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/23.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/box.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/24.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/zebra.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/25.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/buzz.mp3' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB2-U6-P46-E2',
    audio: 'img/FriendsPlus/Page46/E2/Audio/audio.e2.p46.mp3',
    video: '',
    songLyric:
      "<p>Look! A nurse! </p>" +
      "<p>/n/, /n/, nurse </p>" +
      "<p>Look! A pen! </p>" +
      "<p>/p/, /p/, pen </p>" +
      "<p>A /n/, /n/ nurse with a /p/, /p/ pen!</p>" +
      "<p>Look! A rabbit!</p>" +
      "<p>/r/, /r/ rabbit </p>" +
      "<p>Look! A sofa! </p>" +
      "<p>/s/, /s/, sofa </p>" +
      "<p>A /r/, /r/, rabbit on a /s/, /s/, sofa! </p>" +
      "<p>Look! A tiger! </p>" +
      "<p>/t/, /t/ tiger </p>" +
      "<p>Look! A van! </p>" +
      "<p>/v/, /v/, van </p>" +
      "<p>A /t/, /t/, tiger in a /v/, /v/, van! </p>" +
      "<p>Look! A zebra! </p>" +
      "<p>/z/, /z/, zebra! </p>" +
      "<p>Look! A box! /ks/, /ks/, box </p>" +
      "<p>A /z/, /z/, zebra in a /ks/, /ks/, box!</p>",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page46/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page46/E2/Audio/tieude.e2.p46.mp3' },
        { url: 'img/FriendsPlus/Page46/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/N.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/P.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/R.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/S.mp3' },


      ],
      [
        { url: 'img/FriendsPlus/Page46/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/nurse.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/lion.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/pen.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/11.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/lollipop.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/12.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/rabbit.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/13.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/car.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/14.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/sofa.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/15.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/octopus.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E1/16.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/T.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/17.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/V.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/18.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/X.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/19.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/Z.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E1/20.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/tiger.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/21.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/hat.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/22.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/van.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/23.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/box.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/24.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/zebra.mp3' },
        { url: 'img/FriendsPlus/Page46/E1/25.jpg', audioUrl: 'img/FriendsPlus/Page46/E1/Audio/buzz.mp3' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB2-U6-P46-E3',
    audio: 'img/FriendsPlus/Page46/E3/Audio/audio.e3.p46.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 7,
    exerciseKey: 'img/FriendsPlus/Page46/E3/Key/answerKeyP46E3.jpg',
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: false,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page46/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page46/E3/Audio/tieude.e3.p46.mp3' },
        { url: 'img/FriendsPlus/Page46/E3/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/4.jpg' },
      ],
      [

        { url: 'img/FriendsPlus/Page46/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page46/E3/6.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page46/E3/7.jpg', input: 2, isCorrect: false },
        { url: 'img/FriendsPlus/Page46/E3/8.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E3/9.jpg', input: 4, isCorrect: false },
        { url: 'img/FriendsPlus/Page46/E3/10.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E3/11.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E3/12.jpg', input: 7, isCorrect: false },
        { url: 'img/FriendsPlus/Page46/E3/13.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/14.jpg', },
        { url: 'img/FriendsPlus/Page46/E3/15.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E3/16.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E3/17.jpg', input: 3, isCorrect: false },
        { url: 'img/FriendsPlus/Page46/E3/18.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E3/19.jpg', input: 5, isCorrect: false },
        { url: 'img/FriendsPlus/Page46/E3/20.jpg', input: 6, isCorrect: false },
        { url: 'img/FriendsPlus/Page46/E3/21.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E3/22.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page46/E3/23.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB2-U6-P46-E4',
    audio: '',
    video: '',
    totalInput : 8,
    component: DesignUnderLine,
    totalInput : 8,
    exerciseKey: 'img/FriendsPlus/Page46/E4/Key/answerKeyP46E4.jpg',
    recorder: false,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page46/E4/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E4/68.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page46/E4/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page46/E4/4.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page46/E4/5.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page46/E4/6.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page46/E4/7.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page46/E4/8.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page46/E4/9.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page46/E4/10.jpg', input: 8, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E4/11.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page46/E4/12.jpg', input: 10 },
        { url: 'img/FriendsPlus/Page46/E4/13.jpg', input: 11 },
        { url: 'img/FriendsPlus/Page46/E4/14.jpg', input: 12 },
        { url: 'img/FriendsPlus/Page46/E4/15.jpg', input: 13 },
        { url: 'img/FriendsPlus/Page46/E4/16.jpg', input: 14 },
        { url: 'img/FriendsPlus/Page46/E4/17.jpg', input: 15, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E4/18.jpg', input: 16 },
        { url: 'img/FriendsPlus/Page46/E4/19.jpg', input: 17 },
        { url: 'img/FriendsPlus/Page46/E4/20.jpg', input: 18 },
        { url: 'img/FriendsPlus/Page46/E4/21.jpg', input: 19 },
        { url: 'img/FriendsPlus/Page46/E4/22.jpg', input: 20 },
        { url: 'img/FriendsPlus/Page46/E4/23.jpg', input: 21 },
        { url: 'img/FriendsPlus/Page46/E4/24.jpg', input: 22 },
        { url: 'img/FriendsPlus/Page46/E4/25.jpg', input: 23 },
        { url: 'img/FriendsPlus/Page46/E4/26.jpg', input: 24 },
        { url: 'img/FriendsPlus/Page46/E4/27.jpg', input: 25 },
        { url: 'img/FriendsPlus/Page46/E4/28.jpg', input: 26 },
        { url: 'img/FriendsPlus/Page46/E4/29.jpg', input: 27 },
        { url: 'img/FriendsPlus/Page46/E4/30.jpg', input: 28 },
        { url: 'img/FriendsPlus/Page46/E4/31.jpg', input: 29 },
        { url: 'img/FriendsPlus/Page46/E4/32.jpg', input: 30 , isCorrect: true},
        { url: 'img/FriendsPlus/Page46/E4/33.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E4/34.jpg' },
        { url: 'img/FriendsPlus/Page46/E4/35.jpg', input: 31 },
        { url: 'img/FriendsPlus/Page46/E4/36.jpg', input: 32 },
        { url: 'img/FriendsPlus/Page46/E4/37.jpg', input: 33 },
        { url: 'img/FriendsPlus/Page46/E4/38.jpg', input: 34 },
        { url: 'img/FriendsPlus/Page46/E4/39.jpg', input: 35 },
        { url: 'img/FriendsPlus/Page46/E4/40.jpg', input: 36 },
        { url: 'img/FriendsPlus/Page46/E4/41.jpg', input: 37, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E4/42.jpg', input: 38 },
        { url: 'img/FriendsPlus/Page46/E4/43.jpg', input: 39 },
        { url: 'img/FriendsPlus/Page46/E4/44.jpg', input: 40 },
        { url: 'img/FriendsPlus/Page46/E4/45.jpg', input: 41 },
        { url: 'img/FriendsPlus/Page46/E4/46.jpg', input: 42, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E4/47.jpg', input: 43 },
        { url: 'img/FriendsPlus/Page46/E4/48.jpg', input: 44 },
        { url: 'img/FriendsPlus/Page46/E4/49.jpg', input: 45 },
        { url: 'img/FriendsPlus/Page46/E4/50.jpg', input: 46 },
        { url: 'img/FriendsPlus/Page46/E4/51.jpg', input: 47 },
        { url: 'img/FriendsPlus/Page46/E4/52.jpg', input: 48 },
        { url: 'img/FriendsPlus/Page46/E4/53.jpg', input: 49 },
        { url: 'img/FriendsPlus/Page46/E4/54.jpg', input: 50 },
        { url: 'img/FriendsPlus/Page46/E4/55.jpg', input: 51 },
        { url: 'img/FriendsPlus/Page46/E4/56.jpg', input: 52, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E4/57.jpg', input: 53 },
        { url: 'img/FriendsPlus/Page46/E4/58.jpg', input: 54 },
        { url: 'img/FriendsPlus/Page46/E4/59.jpg', input: 55 },
        { url: 'img/FriendsPlus/Page46/E4/60.jpg', input: 56 },
        { url: 'img/FriendsPlus/Page46/E4/61.jpg', input: 57 },
        { url: 'img/FriendsPlus/Page46/E4/62.jpg', input: 58 },
        { url: 'img/FriendsPlus/Page46/E4/63.jpg', input: 59 },
        { url: 'img/FriendsPlus/Page46/E4/64.jpg', input: 60, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E4/65.jpg', input: 61 },
        { url: 'img/FriendsPlus/Page46/E4/66.jpg', input: 62, isCorrect: true },
        { url: 'img/FriendsPlus/Page46/E4/67.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page46/E4/68.jpg' },
      ],
    ],
  },
  5: { // Exercise num
    unit: 'Unit 6',
    id: 'SB2-U6-P46-E5',
    audio: '',
    video: '',
    component: UnderLine,
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page46/E5/1.jpg' },
      ],
    ],
  },

}

export default json;
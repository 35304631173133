import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';





const json = {
  1: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P40-E1',
    audio: 'img/FriendsPlus/Page40/Audio/audio.e1.p40.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page40/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/tieude.e1.p40.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/R.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/S.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/T.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/U.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/12.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/rabbit (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page40/E1/13.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/sofa (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page40/E1/14.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/tiger (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page40/E1/15.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/umbrella (mp3cut.net).mp3' },
      ],
    ],
  },

  2: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P40-E2',
    audio: 'img/FriendsPlus/Page40/Audio/audio.e2.p40.mp3',
    video: '',
    songLyric:
      "<p>Where is the rabbit? </p>" +
      "<p>/r/ /r/</p>"+
      "<p>Rabbit </p>" +
      "<p>Where is the sofa? </p>" +
      "<p>/s/ /s/ </p>" +
      "<p>Sofa </p>" +
      "<p>Where is the tiger? </p>" +
      "<p>/t/ /t/ </p>" +
      "<p>Tiger </p>" +
      "<p>Where is the umbrella? </p>" +
      "<p>/ʌ / /ʌ /</p>" +
      "<p> Umbrella</p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page40/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/tieude.e2.p40.mp3' },
        { url: 'img/FriendsPlus/Page40/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/R.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/S.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/T.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/U.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/12.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/rabbit (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page40/E1/13.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/sofa (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page40/E1/14.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/tiger (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page40/E1/15.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/umbrella (mp3cut.net).mp3' },
      ],
    ],
  },


  3: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P40-E3',
    audio: 'img/FriendsPlus/Page40/Audio/audio.e3.p40.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page40/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/tieude.e3.p40.mp3' },
        { url: 'img/FriendsPlus/Page40/E3/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/4.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/R.mp3' },
        { url: 'img/FriendsPlus/Page40/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/6.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/S.mp3' },
        { url: 'img/FriendsPlus/Page40/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/8.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/T.mp3' },
        { url: 'img/FriendsPlus/Page40/E3/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E3/10.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/car (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page40/E3/11.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/octopus (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page40/E3/12.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/hat (mp3cut.net).mp3' },
      ],
    ],
  },

  4: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P40-E4',
    audio: '',
    video: '',
    totalInput: 11,
    component: DesignUnderLine,
    exerciseKey: 'img/FriendsPlus/Page40/E4/Key/answerKeyP40E4.jpg',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page40/E4/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page40/E4/3.jpg', input: 1 , isCorrect: true},
        { url: 'img/FriendsPlus/Page40/E4/4.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page40/E4/5.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page40/E4/6.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page40/E4/7.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page40/E4/8.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E4/9.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page40/E4/10.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page40/E4/11.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page40/E4/12.jpg', input: 10 , isCorrect: true},
        { url: 'img/FriendsPlus/Page40/E4/13.jpg', input: 11 },
        { url: 'img/FriendsPlus/Page40/E4/14.jpg', input: 12, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E4/15.jpg', input: 13 },
        { url: 'img/FriendsPlus/Page40/E4/16.jpg', input: 14 },
        { url: 'img/FriendsPlus/Page40/E4/17.jpg', input: 15, isCorrect: true},
        { url: 'img/FriendsPlus/Page40/E4/18.jpg', input: 16 },
        { url: 'img/FriendsPlus/Page40/E4/19.jpg', input: 17 },
        { url: 'img/FriendsPlus/Page40/E4/20.jpg', input: 18 },
        { url: 'img/FriendsPlus/Page40/E4/21.jpg', input: 19 },
        { url: 'img/FriendsPlus/Page40/E4/22.jpg', input: 20 , isCorrect: true},
        { url: 'img/FriendsPlus/Page40/E4/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E4/24.jpg' },
        { url: 'img/FriendsPlus/Page40/E4/25.jpg', input: 21, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E4/26.jpg', input: 22 },
        { url: 'img/FriendsPlus/Page40/E4/27.jpg', input: 23 },
        { url: 'img/FriendsPlus/Page40/E4/28.jpg', input: 24 },
        { url: 'img/FriendsPlus/Page40/E4/29.jpg', input: 25 },
        { url: 'img/FriendsPlus/Page40/E4/30.jpg', input: 26, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E4/31.jpg', input: 27 },
        { url: 'img/FriendsPlus/Page40/E4/32.jpg', input: 28 },
        { url: 'img/FriendsPlus/Page40/E4/33.jpg', input: 29 },
        { url: 'img/FriendsPlus/Page40/E4/34.jpg', input: 30 , isCorrect: true},
        { url: 'img/FriendsPlus/Page40/E4/35.jpg', input: 31 },
        { url: 'img/FriendsPlus/Page40/E4/36.jpg', input: 32 },
        { url: 'img/FriendsPlus/Page40/E4/37.jpg', input: 33 , isCorrect: true},
        { url: 'img/FriendsPlus/Page40/E4/38.jpg', input: 34 },
        { url: 'img/FriendsPlus/Page40/E4/39.jpg', input: 35 },
        { url: 'img/FriendsPlus/Page40/E4/40.jpg', input: 36 },
        { url: 'img/FriendsPlus/Page40/E4/41.jpg', input: 37 , isCorrect: true},
        { url: 'img/FriendsPlus/Page40/E4/42.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E4/43.jpg' },
      ],
    ],
  },

  5: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P40-E5',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page40/E5/1.jpg' },
      ],
    ],
  },

}

export default json;

import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {

  1: { // Exercise num
    unit: 'Unit 2',
    id: 'SB2-U2-P20-E1',
    audio: 'img/FriendsPlus/Page20/Audio/audio.e1.p20.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page20/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page20/Audio/tieude.e1.p20.mp3' },
        { url: 'img/FriendsPlus/Page20/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page20/Audio/S.mp3' },
        { url: 'img/FriendsPlus/Page20/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page20/Audio/sofa (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page20/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page20/Audio/sock (mp3cut.net).mp3' },
      ],


    ],
  },

  2: { // Exercise num
    unit: 'Unit 2',
    id: 'SB2-U2-P20-E2',
    audio: 'img/FriendsPlus/Page20/Audio/audio.e2.p20.mp3',
    video: '',
    songLyric:
      "<p>There's a sock on the sofa, sock on the sofa, sock on the sofa /s/ /s/ /s/  </p>" +
      "<p>There's a sock on the sofa, sock on the sofa, sock on the sofa /s/ /s/ /s/  </p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page20/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page20/Audio/tieude.e2.p20.mp3' },
        { url: 'img/FriendsPlus/Page20/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page20/Audio/S.mp3' },
        { url: 'img/FriendsPlus/Page20/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page20/Audio/sofa (mp3cut.net).mp3' },
        { url: 'img/FriendsPlus/Page20/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page20/Audio/sock (mp3cut.net).mp3' },
      ],


    ],
  },

  3: { // Exercise num
    unit: 'Unit 2',
    id: 'SB2-U2-P20-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page20/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page20/E3/3.jpg', input: 1, audioUrl: 'img/FriendsPlus/Page20/Audio/S.mp3' },
        { url: 'img/FriendsPlus/Page20/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E3/5.jpg' },
      ],


    ],
  },

  4: { // Exercise num
    unit: 'Unit 2',
    id: 'SB2-U2-P20-E4',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: 'img/FriendsPlus/Page20/E4/Key/answerKeyP20E4.jpg',
    recorder: false,
    isAllowSubmit: false,
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page20/E4/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E4/2.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page20/E4/3.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page20/E4/4.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page20/E4/5.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page20/E4/6.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page20/E4/7.jpg', input: 6 },
      ],
      [
        { url: 'img/FriendsPlus/Page20/E4/8.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page20/E4/9.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page20/E4/10.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page20/E4/11.jpg', input: 10 },
        { url: 'img/FriendsPlus/Page20/E4/12.jpg', input: 11, isCorrect: true },
      ],


    ],
  },


}
export default json;

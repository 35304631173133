import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
    1: { // Exercise num
        unit : 'Unit 6',
        id : 'SB2-U6-P45-E1',
        audio: 'img/FriendsPlus/Page45/E1/Audio/audio.e1.p45.mp3',
        video: '',
        component: UnderLine,
        isAllowSubmit:true,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page45/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page45/E1/2.jpg',audioUrl: 'img/FriendsPlus/Page45/E1/Audio/tieude.e1.p45.mp3'},
                { url: 'img/FriendsPlus/Page45/E1/3.jpg' },
                
            ],
        
            [
                { url: 'img/FriendsPlus/Page45/E1/4.jpg',audioUrl: 'img/FriendsPlus/Page45/E1/Audio/11.mp3' },
                { url: 'img/FriendsPlus/Page45/E1/5.jpg',audioUrl: 'img/FriendsPlus/Page45/E1/Audio/12.mp3'},
                { url: 'img/FriendsPlus/Page45/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page45/E1/Audio/13.mp3' },
                { url: 'img/FriendsPlus/Page45/E1/7.jpg',audioUrl: 'img/FriendsPlus/Page45/E1/Audio/14.mp3' },
                { url: 'img/FriendsPlus/Page45/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page45/E1/Audio/15.mp3' },
              
            ],
            [
                { url: 'img/FriendsPlus/Page45/E1/9.jpg' , audioUrl: 'img/FriendsPlus/Page45/E1/Audio/16.mp3'},
                { url: 'img/FriendsPlus/Page45/E1/10.jpg',audioUrl: 'img/FriendsPlus/Page45/E1/Audio/17.mp3'},
                { url: 'img/FriendsPlus/Page45/E1/11.jpg', audioUrl: 'img/FriendsPlus/Page45/E1/Audio/18.mp3' },
                { url: 'img/FriendsPlus/Page45/E1/12.jpg',audioUrl: 'img/FriendsPlus/Page45/E1/Audio/19.mp3' },
                { url: 'img/FriendsPlus/Page45/E1/13.jpg', audioUrl: 'img/FriendsPlus/Page45/E1/Audio/20.mp3' },              
            ],
        ],
    },
    2: { // Exercise num
        unit : 'Unit 6',
        id : 'SB2-U6-P45-E2',
        audio: 'img/FriendsPlus/Page45/E2/Audio/audio.e2.p45.mp3',
        video: '',
        isAllowSubmit:true,
        component: UnderLine,
        songLyric:
        "<p>What can you see? What can you see? </p>" +
        "<p>I see 11 moms, 11,11! </p>" +
        "<p>And I see 12 dads, 12, 12! </p>" +
        "<p>What can you see? What can you see? </p>" +
        "<p>13 sandwiches, 13, 13! </p>" +
        "<p>And 14 water bottles, 14, 14! </p>" +
        "<p>What can you see? What can you see? </p>" +
        "<p>There are 15 dresses, 15, 15! </p>" +
        "<p>And 16 pants, 16, 16! </p>" +
        "<p>What can you see? What can you see? </p>" +
        "<p>17 bikes, 17, 17! </p>" +
        "<p>And 18 boats, 18, 18! </p>" +
        "<p>What can you see? What can you see? </p>" +
        "<p>There are 19 trees, 19, 19! </p>" +
        "<p>And 20 Frisbees, 20, 20! </p>" +
        "<p>11, 12, 13, 14, 15, 16, 17, 18, 19, 20!</p>" ,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page45/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page45/E2/2.jpg',audioUrl: 'img/FriendsPlus/Page45/E2/Audio/tieude.e2.p45.mp3' },
                { url: 'img/FriendsPlus/Page45/E2/3.jpg' },
            ],
            [
              { url: 'img/FriendsPlus/Page45/E1/4.jpg',audioUrl: 'img/FriendsPlus/Page45/E1/Audio/11.mp3' },
              { url: 'img/FriendsPlus/Page45/E1/5.jpg',audioUrl: 'img/FriendsPlus/Page45/E1/Audio/12.mp3'},
              { url: 'img/FriendsPlus/Page45/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page45/E1/Audio/13.mp3' },
              { url: 'img/FriendsPlus/Page45/E1/7.jpg',audioUrl: 'img/FriendsPlus/Page45/E1/Audio/14.mp3' },
              { url: 'img/FriendsPlus/Page45/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page45/E1/Audio/15.mp3' },
            
          ],
          [
              { url: 'img/FriendsPlus/Page45/E1/9.jpg' , audioUrl: 'img/FriendsPlus/Page45/E1/Audio/16.mp3'},
              { url: 'img/FriendsPlus/Page45/E1/10.jpg',audioUrl: 'img/FriendsPlus/Page45/E1/Audio/17.mp3'},
              { url: 'img/FriendsPlus/Page45/E1/11.jpg', audioUrl: 'img/FriendsPlus/Page45/E1/Audio/18.mp3' },
              { url: 'img/FriendsPlus/Page45/E1/12.jpg',audioUrl: 'img/FriendsPlus/Page45/E1/Audio/19.mp3' },
              { url: 'img/FriendsPlus/Page45/E1/13.jpg', audioUrl: 'img/FriendsPlus/Page45/E1/Audio/20.mp3' },              
          ],
        ],
    },
    3: { // Exercise num
        unit : 'Unit 6',
        id : 'SB2-U6-P45-E3',
        audio: 'img/FriendsPlus/Page45/E3/Audio/audio.e3.p45.mp3',
        video: '',
        component: UnderLine,
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page45/E3/1.jpg' },   
                { url: 'img/FriendsPlus/Page45/E3/2.jpg',audioUrl: 'img/FriendsPlus/Page45/E3/Audio/tieude.e3.p45.mp3'  },   
                { url: 'img/FriendsPlus/Page45/E3/3.jpg' },   
            ],
            [
                { url: 'img/FriendsPlus/Page45/E3/4.jpg' },   
                { url: 'img/FriendsPlus/Page45/E3/5.jpg' },   
                { url: 'img/FriendsPlus/Page45/E3/6.jpg' },   
                { url: 'img/FriendsPlus/Page45/E3/7.jpg' },   
                { url: 'img/FriendsPlus/Page45/E3/8.jpg' },   
            ],
        ],
    },
    4: { // Exercise num
        unit : 'Unit 6',
        id : 'SB2-U6-P45-E4',
      audio: '',
      video: '',
      component: UnderLine,
      // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
      recorder: false,
      isAllowSubmit: true,
      questionImage: [ // Row
        [
          { url: 'img/FriendsPlus/Page45/E4/1.jpg' },
        ],
      
        [
          { url: 'img/FriendsPlus/Page45/E4/2.jpg' },
          { url: 'img/FriendsPlus/Page45/E4/3.jpg' },
          { url: 'img/FriendsPlus/Page45/E4/4.jpg'    },
          { url: 'img/FriendsPlus/Page45/E4/5.jpg'   },
          { url: 'img/FriendsPlus/Page45/E4/6.jpg'   },
        ],
        [
          { url: 'img/FriendsPlus/Page45/E4/7.jpg'   },
        ], 
      ],
    },
    5: { // Exercise num
        unit : 'Unit 6',
        id : 'SB2-U6-P45-E5',
      audio: '',
      video: '',
      component: UnderLine,
      recorder: true,
      isAllowSubmit: true,
      questionImage: [ // Row
          [
              { url: 'img/FriendsPlus/Page45/E5/1.jpg' },   
          ],
      ],
  },
  
}

export default json;
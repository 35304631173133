import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
    1: { // Exercise num
        unit : 'Fluency Time! 1',
        id : 'SB2-U3-P28-E1',
        audio: 'img/FriendsPlus/Page28/E1/Audio/audio.e1.p28.mp3',
        video: '',
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page28/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page28/E1/2.jpg',audioUrl: 'img/FriendsPlus/Page28/E1/Audio/tieude.e1.p28.mp3' },
                { url: 'img/FriendsPlus/Page28/E1/3.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page28/E1/4.jpg',},
                { url: 'img/FriendsPlus/Page28/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page28/E1/Audio/anh1.e1.p28.mp3' },
                { url: 'img/FriendsPlus/Page28/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page28/E1/Audio/anh2.e1.p28.mp3' },
            ],
        ],
    },
    2: { // Exercise num
        unit : 'Fluency Time! 1',
        id : 'SB2-U3-P28-E2',
        audio: 'img/FriendsPlus/Page28/E2/Audio/audio.e2.p28.mp3',
        video: '',
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page28/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page28/E2/2.jpg',audioUrl: 'img/FriendsPlus/Page28/E2/Audio/tieude.e2.p28.mp3' },
                { url: 'img/FriendsPlus/Page28/E2/3.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page28/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page28/E2/Audio/audio.e2.p28.mp3' },
            ],
        ],
    },
    3: { // Exercise num
        unit : 'Fluency Time! 1',
        id : 'SB2-U3-P28-E3',
        audio: '',
        video: '',
        isAllowSubmit: true,
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page28/E3/1.jpg'},
                
            ],
          
        ],
    },
  
}

export default json;
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
    1: { // Exercise num
        unit : 'Unit 2',
        id : 'SB2-U2-P16-E1',
        audio: 'img/FriendsPlus/Page16/E1/Audio/audio.e1.p16.mp3',
        video: '',
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page16/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page16/E1/2.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/tieude.e1.p16.mp3' },
                { url: 'img/FriendsPlus/Page16/E1/3.jpg'},
               
            ],
        
            [
                { url: 'img/FriendsPlus/Page16/E1/4.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/happy.mp3' },
                { url: 'img/FriendsPlus/Page16/E1/5.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/sad.mp3' },
                { url: 'img/FriendsPlus/Page16/E1/6.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/hungry.mp3'  },
                { url: 'img/FriendsPlus/Page16/E1/7.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/thirsty.mp3' },
                { url: 'img/FriendsPlus/Page16/E1/8.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/hot.mp3'  },
                { url: 'img/FriendsPlus/Page16/E1/9.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/cold.mp3'  },
            ],
        ],
    },
    2: { // Exercise num
        unit : 'Unit 2',
        id : 'SB2-U2-P16-E2',
        audio: 'img/FriendsPlus/Page16/E2/Audio/audio.e2.p16.mp3',
        video: '',
        isAllowSubmit: true,
        songLyric:
        "<p>happy, happy </p>"+
        "<p>sad, sad </p>"+
        "<p>hungry, hungry </p>"+
        "<p>thirsty, thirsty </p>"+
        "<p>hot, hot </p>"+
        "<p>cold, cold </p>",
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
          [
            { url: 'img/FriendsPlus/Page16/E2/1.jpg' },
            { url: 'img/FriendsPlus/Page16/E2/2.jpg' ,audioUrl: 'img/FriendsPlus/Page16/E2/Audio/tieude.e2.p16.mp3'},
            { url: 'img/FriendsPlus/Page16/E2/3.jpg'},
           
        ],
    
        [
            { url: 'img/FriendsPlus/Page16/E1/4.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/happy.mp3' },
            { url: 'img/FriendsPlus/Page16/E1/5.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/sad.mp3' },
            { url: 'img/FriendsPlus/Page16/E1/6.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/hungry.mp3'  },
            { url: 'img/FriendsPlus/Page16/E1/7.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/thirsty.mp3' },
            { url: 'img/FriendsPlus/Page16/E1/8.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/hot.mp3'  },
            { url: 'img/FriendsPlus/Page16/E1/9.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/cold.mp3'  },
        ],
        ],
    },
    3: { // Exercise num
        unit : 'Unit 2',
        id : 'SB2-U2-P16-E3',
        audio: '',
        video: '',
        component: UnderLine,
        isAllowSubmit:true,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page16/E3/1.jpg' },
              
                
            ],
        
            [
                { url: 'img/FriendsPlus/Page16/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page16/E3/3.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/thirsty.mp3' },
                { url: 'img/FriendsPlus/Page16/E3/4.jpg'},
               
            ],
            [
                { url: 'img/FriendsPlus/Page16/E3/5.jpg'},
                { url: 'img/FriendsPlus/Page16/E3/6.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/hot.mp3'},
                { url: 'img/FriendsPlus/Page16/E3/7.jpg'},
                { url: 'img/FriendsPlus/Page16/E3/8.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/thirsty.mp3'},
                { url: 'img/FriendsPlus/Page16/E3/9.jpg'},
            ],
            [
                { url: 'img/FriendsPlus/Page16/E3/10.jpg',  },
                { url: 'img/FriendsPlus/Page16/E3/11.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/hot.mp3' },
                { url: 'img/FriendsPlus/Page16/E3/12.jpg',  },
                { url: 'img/FriendsPlus/Page16/E3/13.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/hungry.mp3'  },
                { url: 'img/FriendsPlus/Page16/E3/14.jpg',  },
            ],
            [
                { url: 'img/FriendsPlus/Page16/E3/15.jpg',  },
                { url: 'img/FriendsPlus/Page16/E3/16.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/hungry.mp3' },
                { url: 'img/FriendsPlus/Page16/E3/17.jpg',  },
                { url: 'img/FriendsPlus/Page16/E3/18.jpg', audioUrl: 'img/FriendsPlus/Page16/E1/Audio/cold.mp3' },
                { url: 'img/FriendsPlus/Page16/E3/19.jpg',  },
            ],
            [
                { url: 'img/FriendsPlus/Page16/E3/20.jpg',  },
                { url: 'img/FriendsPlus/Page16/E3/21.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/cold.mp3' },
                { url: 'img/FriendsPlus/Page16/E3/22.jpg',  }              
            ],
            [
                { url: 'img/FriendsPlus/Page16/E3/23.jpg',  },
            ],
            [
                { url: 'img/FriendsPlus/Page16/E3/24.jpg',  },
                { url: 'img/FriendsPlus/Page16/E3/25.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/sad.mp3' },
                { url: 'img/FriendsPlus/Page16/E3/26.jpg',  }              
            ],
            [
                { url: 'img/FriendsPlus/Page16/E3/27.jpg',  },
            ],
            [
                { url: 'img/FriendsPlus/Page16/E3/28.jpg',  },
                { url: 'img/FriendsPlus/Page16/E3/29.jpg',audioUrl: 'img/FriendsPlus/Page16/E1/Audio/happy.mp3' },
                { url: 'img/FriendsPlus/Page16/E3/30.jpg',  }              
            ],
            [
              { url: 'img/FriendsPlus/Page16/E3/31.jpg',  },
            ],
            
        ],
    },

 
  
}

export default json;
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
    1: { // Exercise num
        unit : 'Culture 4',
        id : 'SB2-C4-P55-E1',
        audio: 'img/FriendsPlus/Page55/E1/Audio/audio.e1.p55.mp3',
        video: '',
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        isAllowSubmit:true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page55/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/2.jpg',audioUrl: 'img/FriendsPlus/Page55/E1/Audio/tieude.e1.p55.mp3'  },
                { url: 'img/FriendsPlus/Page55/E1/3.jpg'},
               
            ],
        
            [
                { url: 'img/FriendsPlus/Page55/E1/4.jpg',audioUrl: 'img/FriendsPlus/Page55/E1/Audio/tohe.mp3' },
                { url: 'img/FriendsPlus/Page55/E1/5.jpg',audioUrl: 'img/FriendsPlus/Page55/E1/Audio/kite.mp3' },
                { url: 'img/FriendsPlus/Page55/E1/6.jpg',audioUrl: 'img/FriendsPlus/Page55/E1/Audio/boardgame.mp3'  },
                { url: 'img/FriendsPlus/Page55/E1/7.jpg',audioUrl: 'img/FriendsPlus/Page55/E1/Audio/doll.mp3' },
               
            ],
        ],
    },
    2: { // Exercise num
        unit : 'Culture 4',
        id : 'SB2-C4-P55-E2',
        audio: 'img/FriendsPlus/Page55/E2/Audio/audio.e2.p55.mp3',
        video: '',
        component: UnderLine,
        isAllowSubmit:true,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
          [
            { url: 'img/FriendsPlus/Page55/E2/1.jpg' },
            { url: 'img/FriendsPlus/Page55/E2/2.jpg',audioUrl: 'img/FriendsPlus/Page55/E2/Audio/tieude.e2.p55.mp3' },
            { url: 'img/FriendsPlus/Page55/E2/3.jpg'},
           
        ],
    
        [
            { url: 'img/FriendsPlus/Page55/E2/4.jpg'},
        ] 
        ],
    },
    3: { // Exercise num
        unit : 'Culture 4',
        id : 'SB2-C4-P55-E3',
        audio: '',
        video: '',
        component: UnderLine,
        isAllowSubmit:true,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page55/E3/1.jpg' },
            ],
        ],
    },

 
  
}

export default json;
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'


const json = {
  1: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P36-E1',
    audio: 'img/FriendsPlus/Page36/Audio/audio.e1.p36.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page36/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/tieude.e1.p36.mp3' },
        { url: 'img/FriendsPlus/Page36/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/seesaw.mp3' },
        { url: 'img/FriendsPlus/Page36/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/slide.mp3' },
        { url: 'img/FriendsPlus/Page36/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/goal.mp3' },
        { url: 'img/FriendsPlus/Page36/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/tree.mp3' },
        { url: 'img/FriendsPlus/Page36/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/pool.mp3' },
        { url: 'img/FriendsPlus/Page36/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/frisbee.mp3' },
      ],
    ],
  },

  2: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P36-E2',
    audio: 'img/FriendsPlus/Page36/Audio/audio.e2.p36.mp3',
    video: '',
    songLyric:
    "<p>seesaw, seesaw, seesaw </p>" +
    "<p>slide, slide, slide </p>" +
    "<p>goal, goal, goal </p>" +
    "<p>tree, tree, tree </p>" +
    "<p>pool, pool, pool </p>" +
    "<p>Frisbee, Frisbee, Frisbee </p>" ,
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page36/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page36/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/tieude.e2.p36.mp3' },
        { url: 'img/FriendsPlus/Page36/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/seesaw.mp3' },
        { url: 'img/FriendsPlus/Page36/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/slide.mp3' },
        { url: 'img/FriendsPlus/Page36/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/goal.mp3' },
        { url: 'img/FriendsPlus/Page36/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/tree.mp3' },
        { url: 'img/FriendsPlus/Page36/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/pool.mp3' },
        { url: 'img/FriendsPlus/Page36/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/frisbee.mp3' },
      ],
    ],
  },

  3: { // Exercise num
    unit : 'Unit 5',
    id : 'SB2-U5-P36-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page36/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/3.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/tree.mp3', input: 1 },
        { url: 'img/FriendsPlus/Page36/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/7.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/pool.mp3' , input: 2},
        { url: 'img/FriendsPlus/Page36/E3/8.jpg'},
        { url: 'img/FriendsPlus/Page36/E3/9.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/slide.mp3' , input: 3},
        { url: 'img/FriendsPlus/Page36/E3/10.jpg'},
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/12.jpg'},
        { url: 'img/FriendsPlus/Page36/E3/13.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/seesaw.mp3' , input: 4},
        { url: 'img/FriendsPlus/Page36/E3/14.jpg'},
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/16.jpg'},
        { url: 'img/FriendsPlus/Page36/E3/17.jpg'},
        { url: 'img/FriendsPlus/Page36/E3/18.jpg'},
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/19.jpg'},
        { url: 'img/FriendsPlus/Page36/E3/20.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/goal.mp3' , input: 5},
        { url: 'img/FriendsPlus/Page36/E3/21.jpg'},
      ],
      [
        { url: 'img/FriendsPlus/Page36/E3/22.jpg' },
      ],
    ],
  },

}

export default json;

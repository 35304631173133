import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';



const json = {
    1: { // Exercise num
        unit : 'Unit 5',
        id : 'SB2-U5-P37-E1',
        audio: 'img/FriendsPlus/Page37/Audio/audio.e1.p37.mp3',
        video: '',
        component: UnderLine,
        // exerciseKey: '',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page37/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page37/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/tieude.e1.p37.mp3' },
                { url: 'img/FriendsPlus/Page37/E1/3.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page37/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/anh1.e1.p37.mp3' },
                { url: 'img/FriendsPlus/Page37/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/anh2.e1.p37.mp3' },
                { url: 'img/FriendsPlus/Page37/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/anh3.e1.p37.mp3' },
            ],
        ],
    },
    2: { // Exercise num
        unit : 'Unit 5',
        id : 'SB2-U5-P37-E2',
        audio: 'img/FriendsPlus/Page37/Audio/sing.e2.p37.mp3',
        video: '',
        songLyric:
            "<p>In the park, in the park </p>" +
            "<p>We like to play in the park. </p>" +
            "<p>Where's the Frisbee? </p>" +
            "<p>Under the tree. </p>" +
            "<p>Where's the boy? </p>" +
            "<p>On the slide. </p>" +
            "<p>Where's the ball? </p>" +
            "<p>Look! It's in the goal!!!</p>" +
            "<p>Yeah!!!! </p>" +
            "<p>In the park, in the park </p>" +
            "<p>We like to play in the park.</p>",
        component: UnderLine,
        // exerciseKey: '',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page37/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page37/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page37/Audio/tieude.e2.p37.mp3' },
                { url: 'img/FriendsPlus/Page37/E2/3.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page37/E2/4.jpg' },
            ],
        ],
    },

    3: { // Exercise num
        unit : 'Unit 5',
        id : 'SB2-U5-P37-E3',
        audio: '',
        video: '',
        component: D1,
        // exerciseKey: '',
        recorder: true,
        inputHeight: '100%',
        exerciseKey:'img/FriendsPlus/Page37/E3/Key/answerKeyP37E3.jpg',
        fontSize: 38,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page37/E3/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page37/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page37/E3/3.jpg', input: true, answer: 'under' },
                { url: 'img/FriendsPlus/Page37/E3/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page37/E3/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page37/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page37/E3/7.jpg', input: true, answer: 'in' },
                { url: 'img/FriendsPlus/Page37/E3/8.jpg' },
            ],
        ],
    },

    4: { // Exercise num
        unit : 'Unit 5',
        id : 'SB2-U5-P37-E4',
        audio: '',
        video: '',
        component: UnderLine,
        // exerciseKey: '',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page37/E4/1.jpg' },
            ],
        ],
    },

}

export default json;

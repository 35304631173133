import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
    1: { // Exercise num
        unit : 'Starter',
        id : 'SB2-S-P4-E1',
        audio: 'img/FriendsPlus/Page4/Audio/audio.e1.p4.mp3',
        video: '',
        component: UnderLine,
        isAllowSubmit: true,
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page4/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page4/E1/2.jpg',audioUrl: 'img/FriendsPlus/Page4/Audio/tieude.e1.p4.mp3' },
                { url: 'img/FriendsPlus/Page4/E1/3.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page4/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/orange.mp3' },
                { url: 'img/FriendsPlus/Page4/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/pink.mp3' },
                { url: 'img/FriendsPlus/Page4/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/brown.mp3' },
                { url: 'img/FriendsPlus/Page4/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/purple.mp3' },
            ],
        ],
    },
    2: { // Exercise num
        unit : 'Starter',
        id : 'SB2-S-P4-E2',
        audio: 'img/FriendsPlus/Page4/Audio/audio.e2.p4.mp3',
        video: '',
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page4/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page4/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/tieude.e2p4.mp3' },
                { url: 'img/FriendsPlus/Page4/E2/3.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page4/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/orange.mp3' },
                { url: 'img/FriendsPlus/Page4/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/pink.mp3' },
                { url: 'img/FriendsPlus/Page4/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/brown.mp3' },
                { url: 'img/FriendsPlus/Page4/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/purple.mp3' },
            ],
        ],
    },
    3: { // Exercise num
        unit : 'Starter',
        id : 'SB2-S-P4-E3',
        audio: 'img/FriendsPlus/Page4/Audio/sing.e3.p4.mp3',
        video: '',
        isAllowSubmit: true,
        songLyric: '<p>Hello, Rosy. Hello, Tim. </p>'+
        "<p>Hello, everyone. </p>"+
        "<p>Hello, Billy and Miss Jones. </p>"+
        "<p>Come and have some fun.</p>",
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page4/E3/1.jpg' },
                { url: 'img/FriendsPlus/Page4/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/tiede.e3.p4.mp3' },
                { url: 'img/FriendsPlus/Page4/E3/3.jpg' },
            ],
        ],
    },
    4: { // Exercise num
        unit : 'Starter',
        id : 'SB2-S-P4-E4',
        audio: '',
        video: '',
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page4/E4/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page4/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page4/E4/3.jpg' , audioUrl: 'img/FriendsPlus/Page4/Audio/orange.mp3'},
                { url: 'img/FriendsPlus/Page4/E4/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page4/E4/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page4/E4/6.jpg' },
                { url: 'img/FriendsPlus/Page4/E4/7.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/brown.mp3'},
                { url: 'img/FriendsPlus/Page4/E4/8.jpg' },
                { url: 'img/FriendsPlus/Page4/E4/9.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/pink.mp3' },
                { url: 'img/FriendsPlus/Page4/E4/10.jpg' },
                { url: 'img/FriendsPlus/Page4/E4/11.jpg' , audioUrl: 'img/FriendsPlus/Page4/Audio/purple.mp3'},
                { url: 'img/FriendsPlus/Page4/E4/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page4/E4/13.jpg' },
                { url: 'img/FriendsPlus/Page4/E4/14.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/pink.mp3' },
                { url: 'img/FriendsPlus/Page4/E4/15.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page4/E4/16.jpg' },
            ],
        ],
    },
}

export default json;
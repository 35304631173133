import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB2-U4-P30-E1',
    audio: 'img/FriendsPlus/Page30/E1/Audio/audio.e1.p30.mp3',
    video: '',
    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page30/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/tieude.e1.p30.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/bus.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/bike.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/boat.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/car.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/truck.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/motorbike.mp3' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB2-U4-P30-E2',
    audio: 'img/FriendsPlus/Page30/E2/Audio/audio.e2.p30.mp3',
    video: '',
    component: UnderLine,
    isAllowSubmit: true,
    songLyric:
      "<p>bus, bus, bus </p>" +
      "<p>bike, bike, bike </p>" +
      "<p>boat, boat, boat </p>" +
      "<p>car, car, car </p>" +
      "<p>truck, truck, truck </p>" +
      "<p>motorbike, motorbike, motorbike </p>",
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page30/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page30/E2/Audio/tieude.e2.p30.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/bus.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/bike.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/boat.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/car.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/truck.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page30/E1/Audio/motorbike.mp3' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB2-U4-P30-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    isAllowSubmit: true,

    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page30/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/3.jpg', input: 1, audioUrl: 'img/FriendsPlus/Page30/E1/Audio/boat.mp3' },
        { url: 'img/FriendsPlus/Page30/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/6.jpg', input: 2, audioUrl: 'img/FriendsPlus/Page30/E1/Audio/bus.mp3' },
        { url: 'img/FriendsPlus/Page30/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E3/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/10.jpg', input: 3, audioUrl: 'img/FriendsPlus/Page30/E1/Audio/truck.mp3' },
        { url: 'img/FriendsPlus/Page30/E3/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/13.jpg', input: 4, audioUrl: 'img/FriendsPlus/Page30/E1/Audio/bike.mp3' },
        { url: 'img/FriendsPlus/Page30/E3/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E3/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/17.jpg', input: 5, audioUrl: 'img/FriendsPlus/Page30/E1/Audio/car.mp3' },
        { url: 'img/FriendsPlus/Page30/E3/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E3/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/21.jpg', input: 6, audioUrl: 'img/FriendsPlus/Page30/E1/Audio/motorbike.mp3' },
        { url: 'img/FriendsPlus/Page30/E3/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page30/E3/23.jpg' },
      ],
    ],
  },

}

export default json;
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
    1: { // Exercise num
        unit : 'Culture 2',
        id : 'SB2-C2-P53-E1',
        audio: 'img/FriendsPlus/Page53/E1/Audio/audio.e1.p53.mp3',
        video: '',
        component: UnderLine,
        isAllowSubmit:true,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page53/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page53/E1/2.jpg',audioUrl: 'img/FriendsPlus/Page53/E1/Audio/tieude.e1.p53.mp3'  },
                { url: 'img/FriendsPlus/Page53/E1/3.jpg'},
               
            ],
        
            [
                { url: 'img/FriendsPlus/Page53/E1/4.jpg',audioUrl: 'img/FriendsPlus/Page53/E1/Audio/card.mp3' },
                { url: 'img/FriendsPlus/Page53/E1/5.jpg',audioUrl: 'img/FriendsPlus/Page53/E1/Audio/banhchung.mp3' },
                { url: 'img/FriendsPlus/Page53/E1/6.jpg',audioUrl: 'img/FriendsPlus/Page53/E1/Audio/lucky money.mp3'  },
                { url: 'img/FriendsPlus/Page53/E1/7.jpg',audioUrl: 'img/FriendsPlus/Page53/E1/Audio/flowers.mp3' },
               
            ],
        ],
    },
    2: { // Exercise num
        unit : 'Culture 2',
        id : 'SB2-C2-P53-E2',
        audio: 'img/FriendsPlus/Page53/E2/Audio/audio.e2.p53.mp3',
        video: '',
        component: UnderLine,
        isAllowSubmit:true,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
          [
            { url: 'img/FriendsPlus/Page53/E2/1.jpg' },
            { url: 'img/FriendsPlus/Page53/E2/2.jpg',audioUrl: 'img/FriendsPlus/Page53/E2/Audio/tieude.e2.p53.mp3' },
            { url: 'img/FriendsPlus/Page53/E2/3.jpg'},
           
        ],
    
        [
            { url: 'img/FriendsPlus/Page53/E2/4.jpg' },
           
        ],
        ],
    },
    3: { // Exercise num
        unit : 'Culture 2',
        id : 'SB2-C2-P53-E3',
        audio: '',
        video: '',
        component: UnderLine,
        isAllowSubmit:true,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page53/E3/1.jpg' },
            ],
        ],
    },

 
  
}

export default json;
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Starter',
    id: 'SB2-S-P6-E1',
    audio: 'img/FriendsPlus/Page6/Audio/audio.e1.p6.mp3',
    video: '',
    component: UnderLine,
    exerciseKey: '',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page6/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/tieude.e1.p6.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/A.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/A.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/B.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/B.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/11.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/C.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/12.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/C.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/15.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/apple.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/17.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/bat.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/18.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/tub.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/19.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/cat.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/22.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/cat.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/23.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/D.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/25.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/E.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/26.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/E.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/28.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/F.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/29.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/F.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/32.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/dog.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/33.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/bird.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/34.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/egg.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/35.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/fig.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/36.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/leaf.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/37.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'SB2-S-P6-E1',
    audio: 'img/FriendsPlus/Page6/Audio/audio.e2.p6.mp3',
    video: '',
    isAllowSubmit: true,
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page6/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/tieude.e2.p6.mp3' },
        { url: 'img/FriendsPlus/Page6/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/A.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/A.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/B.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/B.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/11.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/C.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/12.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/C.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/15.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/apple.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/17.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/bat.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/18.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/tub.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/19.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/cat.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/22.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/cat.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/23.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/D.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/25.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/E.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/26.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/E.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/28.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/F.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/29.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/F.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/32.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/dog.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/33.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/bird.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/34.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/egg.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/35.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/fig.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/36.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/leaf.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/37.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Starter',
    id: 'SB2-S-P6-E3',
    audio: 'img/FriendsPlus/Page6/Audio/audio.e3.p6.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 7,
    // isAllowSubmit:true,
    exerciseKey: 'img/FriendsPlus/Page6/E3/Key/answerKeyP6E3.jpg',
    recorder: false,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page6/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/tieude.e3.p6.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E3/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/5.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/7.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/9.jpg', input: 3, isCorrect: false },
        { url: 'img/FriendsPlus/Page6/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/11.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/13.jpg', input: 5, isCorrect: false },
        { url: 'img/FriendsPlus/Page6/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/15.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/17.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E3/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/21.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page6/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/23.jpg', input: 2, isCorrect: false },
        { url: 'img/FriendsPlus/Page6/E3/24.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/25.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/26.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/27.jpg', input: 4, isCorrect: false },
        { url: 'img/FriendsPlus/Page6/E3/28.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/29.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/30.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/31.jpg', input: 6, isCorrect: false },
        { url: 'img/FriendsPlus/Page6/E3/32.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/33.jpg', input: 7, isCorrect: false },
        { url: 'img/FriendsPlus/Page6/E3/34.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E3/35.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Starter',
    id: 'SB2-S-P6-E4',
    audio: '',
    totalInput : 6,
    video: '',
    component: DesignUnderLine,
    exerciseKey: 'img/FriendsPlus/Page6/E4/Key/answerKeyP6E4.jpg',
    recorder: false,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page6/E4/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page6/E4/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page6/E4/4.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page6/E4/5.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page6/E4/6.jpg', input: 4 , isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E4/7.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page6/E4/8.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page6/E4/9.jpg', input: 7 , isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E4/10.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page6/E4/11.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page6/E4/12.jpg', input: 10 },
        { url: 'img/FriendsPlus/Page6/E4/13.jpg', input: 11 },
        { url: 'img/FriendsPlus/Page6/E4/14.jpg', input: 12 },
        { url: 'img/FriendsPlus/Page6/E4/15.jpg', input: 13 },
        { url: 'img/FriendsPlus/Page6/E4/16.jpg', input: 14 },
        { url: 'img/FriendsPlus/Page6/E4/17.jpg', input: 15 },
        { url: 'img/FriendsPlus/Page6/E4/18.jpg', input: 16 },
        { url: 'img/FriendsPlus/Page6/E4/19.jpg', input: 17 , isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E4/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E4/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E4/22.jpg' },
        { url: 'img/FriendsPlus/Page6/E4/23.jpg', input: 18 },
        { url: 'img/FriendsPlus/Page6/E4/24.jpg', input: 19 },
        { url: 'img/FriendsPlus/Page6/E4/25.jpg', input: 20 },
        { url: 'img/FriendsPlus/Page6/E4/26.jpg', input: 21 , isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E4/27.jpg', input: 22 },
        { url: 'img/FriendsPlus/Page6/E4/28.jpg', input: 23 },
        { url: 'img/FriendsPlus/Page6/E4/29.jpg', input: 24 , isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E4/30.jpg', input: 25 },
        { url: 'img/FriendsPlus/Page6/E4/31.jpg', input: 26 },
        { url: 'img/FriendsPlus/Page6/E4/32.jpg', input: 27 },
        { url: 'img/FriendsPlus/Page6/E4/33.jpg', input: 28 },
        { url: 'img/FriendsPlus/Page6/E4/34.jpg', input: 29 },
        { url: 'img/FriendsPlus/Page6/E4/35.jpg', input: 30 },
        { url: 'img/FriendsPlus/Page6/E4/36.jpg', input: 31 },
        { url: 'img/FriendsPlus/Page6/E4/37.jpg', input: 32 },
        { url: 'img/FriendsPlus/Page6/E4/38.jpg', input: 33 },
        { url: 'img/FriendsPlus/Page6/E4/39.jpg', input: 34 },
        { url: 'img/FriendsPlus/Page6/E4/40.jpg', input: 35 , isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E4/41.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E4/42.jpg' },
      ]
    ],
  },

  5: { // Exercise num
    unit: 'Starter',
    id: 'SB2-S-P6-E5',
    audio: '',
    video: '',
    component: UnderLine,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page6/E5/1.jpg' },
      ],
    ],
  },
}

export default json;
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
    1: { // Exercise num
        unit : 'Unit 1',
        id : 'SB2-U1-P14-E1',
        audio: 'img/FriendsPlus/Page14/E1/Audio/audio.e1.p14.mp3',
        video: '',
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page14/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page14/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page14/E1/Audio/tieude.e1.p14.mp3'},
                { url: 'img/FriendsPlus/Page14/E1/3.jpg' },
            ],
        
            [
                { url: 'img/FriendsPlus/Page14/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page14/E1/Audio/P.mp3' },
                { url: 'img/FriendsPlus/Page14/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page14/E1/Audio/panda.mp3' },
                { url: 'img/FriendsPlus/Page14/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page14/E1/Audio/pen.mp3' },
               
            ],
            
        ],
    },
    2: { // Exercise num
        unit : 'Unit 1',
        id : 'SB2-U1-P14-E2',
        audio: 'img/FriendsPlus/Page14/E2/Audio/audio.e2.p14.mp3',
        video: '',
        component: UnderLine,
        isAllowSubmit: true,
        songLyric:
        "<p>Polly is a panda. </p>"+
        "<p>She has a pen. </p>"+
        "<p>/p/ /p/ /p/ /p/ /p/ /p/ </p>"+
        "<p>Polly is a panda. </p>"+
        "<p>She has a pen. </p>"+
        "<p>/p/ /p/ /p/ /p/ /p/ /p/ </p>",
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page14/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page14/E2/2.jpg',audioUrl: 'img/FriendsPlus/Page14/E2/Audio/tieude.e2.p14.mp3'},
                { url: 'img/FriendsPlus/Page14/E2/3.jpg' },
            ],
        
            [
              { url: 'img/FriendsPlus/Page14/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page14/E1/Audio/P.mp3' },
              { url: 'img/FriendsPlus/Page14/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page14/E1/Audio/panda.mp3' },
              { url: 'img/FriendsPlus/Page14/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page14/E1/Audio/pen.mp3' },
               
            ],
            
        ],
    },
    3: { // Exercise num
        unit : 'Unit 1',
        id : 'SB2-U1-P14-E3',
        audio: '',
        video: '',
        isAllowSubmit: true,
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page14/E3/1.jpg' },
            ],
        ],
    },
    4: { // Exercise num
        unit : 'Unit 1',
        id : 'SB2-U1-P14-E4',
        totalInput : 8,
        audio: '',
        video: '',
        isAllowSubmit:true,
        component: DesignUnderLine,
        exerciseKey:'img/FriendsPlus/Page14/E4/Key/answerKeyP14E4.jpg',
        recorder: false,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page14/E4/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page14/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page14/E4/3_3.jpg',input:1 },
                { url: 'img/FriendsPlus/Page14/E4/4.jpg',input:2 },
                { url: 'img/FriendsPlus/Page14/E4/5.jpg',input:3, isCorrect: true },
                { url: 'img/FriendsPlus/Page14/E4/6.jpg',input:4 , isCorrect: true},
                { url: 'img/FriendsPlus/Page14/E4/7.jpg',input:5 , isCorrect: true},
                { url: 'img/FriendsPlus/Page14/E4/8.jpg',input:6 },
                { url: 'img/FriendsPlus/Page14/E4/9.jpg',input:7 },
                { url: 'img/FriendsPlus/Page14/E4/10.jpg'},
            ],
            [
                { url: 'img/FriendsPlus/Page14/E4/11.jpg'},
                { url: 'img/FriendsPlus/Page14/E4/12.jpg',input:8 },
                { url: 'img/FriendsPlus/Page14/E4/13.jpg',input:9 },
                { url: 'img/FriendsPlus/Page14/E4/14.jpg',input:10, isCorrect: true },
                { url: 'img/FriendsPlus/Page14/E4/15.jpg',input:11 },
                { url: 'img/FriendsPlus/Page14/E4/16.jpg',input:12, isCorrect: true },
                { url: 'img/FriendsPlus/Page14/E4/17.jpg',input:13 },
                { url: 'img/FriendsPlus/Page14/E4/18.jpg',input:14 },
                { url: 'img/FriendsPlus/Page14/E4/19.jpg'},
            ],
            [
                { url: 'img/FriendsPlus/Page14/E4/20.jpg'},
                { url: 'img/FriendsPlus/Page14/E4/21.jpg',input:15 },
                { url: 'img/FriendsPlus/Page14/E4/22.jpg',input:16 },
                { url: 'img/FriendsPlus/Page14/E4/23.jpg',input:17, isCorrect: true },
                { url: 'img/FriendsPlus/Page14/E4/24.jpg',input:18 , isCorrect: true},
                { url: 'img/FriendsPlus/Page14/E4/25.jpg',input:19 },
                { url: 'img/FriendsPlus/Page14/E4/26.jpg',input:20 },
                { url: 'img/FriendsPlus/Page14/E4/27.jpg',input:21 },
                { url: 'img/FriendsPlus/Page14/E4/28.jpg'},
            ],
            [
                { url: 'img/FriendsPlus/Page14/E4/29.jpg'},
                { url: 'img/FriendsPlus/Page14/E4/30.jpg',input:22 },
                { url: 'img/FriendsPlus/Page14/E4/31.jpg',input:23 },
                { url: 'img/FriendsPlus/Page14/E4/32.jpg',input:24 , isCorrect: true},
                { url: 'img/FriendsPlus/Page14/E4/33.jpg',input:25 },
                { url: 'img/FriendsPlus/Page14/E4/34.jpg',input:26 },
                { url: 'img/FriendsPlus/Page14/E4/35.jpg',input:27 },
                { url: 'img/FriendsPlus/Page14/E4/36.jpg',input:28 },
                { url: 'img/FriendsPlus/Page14/E4/37.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page14/E4/38.jpg' },
            ],
        ],
    },


 
  
}

export default json;
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
    1: { // Exercise num
        unit : 'Unit 2',
        id : 'SB2-U2-P17-E1',
        audio: '',
        video: '',
        component: UnderLine,
        exerciseKey: '',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page17/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page17/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page17/E1/Audio/tieude.e1.p17.mp3'  },
                { url: 'img/FriendsPlus/Page17/E1/3.jpg' },
            ],
          
            [
                { url: 'img/FriendsPlus/Page17/E1/4.jpg',audioUrl: 'img/FriendsPlus/Page17/E1/Audio/hehappy.mp3'},
                { url: 'img/FriendsPlus/Page17/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page17/E1/Audio/shehungry.mp3' },
               
            ],
        ],
    },
    2: { // Exercise num
        unit : 'Unit 2',
        id : 'SB2-U2-P17-E2',
        audio: 'img/FriendsPlus/Page17/E2/Audio/sing.e2.p17.mp3',
        video: '',
        isAllowSubmit: true,
        songLyric:
        "<p>Happy, happy </p>"+
        "<p>Look at the boy </p>"+
        "<p>He's happy, happy, happy </p>"+
        "<p>Happy as can be </p>"+
        "<p>Sad, sad </p>"+
        "<p>Look at the girl </p>"+
        "<p>She's sad, sad, sad </p>"+
        "<p>Sad as can be </p>"+
        "<p>Hungry, hungry </p>"+
        "<p>Look at the boy </p>"+
        "<p>He's hungry, hungry, hungry </p>"+
        "<p>Hungry as can be </p>"+
        "<p>Thirsty, thirsty </p>"+
        "<p>Look at the girl </p>"+
        "<p>She's thirsty, thirsty, thirsty </p>"+
        "<p>Thirsty as can be</p>",
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page17/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page17/E2/2.jpg',audioUrl: 'img/FriendsPlus/Page17/E2/Audio/tieude.e2.p17.mp3' },
                { url: 'img/FriendsPlus/Page17/E2/3.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page17/E2/4.jpg' },
            ],
        ],
    },
    3: { // Exercise num
        unit : 'Unit 2',
        id : 'SB2-U2-P17-E3',
        audio: '',
        video: '',
        isAllowSubmit: true,
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page17/E3/1.jpg' },
               
            ],
        ],
    },
    4: { // Exercise num
        unit : 'Unit 2',
        id : 'SB2-U2-P17-E4',
        audio: '',
        video: '',
        isAllowSubmit: true,
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page17/E4/1.jpg' },
               
            ],
        ],
    },

}

export default json;
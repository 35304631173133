import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
    1: { // Exercise num
        unit : 'Unit 1',
        id : 'SB2-U1-P15-E1',
        audio: 'img/FriendsPlus/Page15/E1/Audio/audio.e1.p15.mp3',
        video: 'Videos/P15E1.mp4',
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page15/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page15/E1/2.jpg',audioUrl: 'img/FriendsPlus/Page15/E1/Audio/tieude.e1.p15.mp3' },
                { url: 'img/FriendsPlus/Page15/E1/3.jpg',videoUrl:'Videos/P15E1.mp4' },
                { url: 'img/FriendsPlus/Page15/E1/4.jpg' },
            ],
        
            [
                { url: 'img/FriendsPlus/Page15/E1/5.jpg', },
                { url: 'img/FriendsPlus/Page15/E1/6.jpg',  },
            ],
            [
                { url: 'img/FriendsPlus/Page15/E1/7.jpg', },
                { url: 'img/FriendsPlus/Page15/E1/8.jpg',  },
            ],
            
        ],
    },
    2: { // Exercise num
        unit : 'Unit 1',
        id : 'SB2-U1-P15-E2',
        audio: '',
        video: '',
        isAllowSubmit: true,
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page15/E2/1.jpg' },
              
            ],
        ],
    },
    3: { // Exercise num
        unit : 'Unit 1',
        id : 'SB2-U1-P15-E3',
        audio: '',
        video: '',
        component: UnderLine,
        // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
        recorder: true,
        isAllowSubmit: true,
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page15/E3/1.jpg' },
                { url: 'img/FriendsPlus/Page15/E3/2.jpg',audioUrl: 'img/FriendsPlus/Page15/E3/Audio/tieude.e3.p15.mp3' },
                { url: 'img/FriendsPlus/Page15/E3/3.jpg' },
                
            ],
        
            [
                { url: 'img/FriendsPlus/Page15/E3/4.jpg',audioUrl: 'img/FriendsPlus/Page15/E3/Audio/anh1.e3.p15.mp3'},
                { url: 'img/FriendsPlus/Page15/E3/5.jpg', audioUrl: 'img/FriendsPlus/Page15/E3/Audio/anh2.e3.p15.mp3' },
            ],
            [
                { url: 'img/FriendsPlus/Page15/E3/6.jpg', audioUrl: 'img/FriendsPlus/Page15/E3/Audio/anh3.e3.p15.mp3'},
                { url: 'img/FriendsPlus/Page15/E3/7.jpg', audioUrl: 'img/FriendsPlus/Page15/E3/Audio/anh4.e3.p15.mp3' },
            ],
            
        ],
    },

 
  
}

export default json;
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'


const json = {
  1: { // Exercise num
    unit : 'Unit 3',
    audio: 'img/FriendsPlus/Page27/Audio/audio.e1.p27.mp3',
    id : 'SB2-U3-P27-E1',
    video: 'Videos/P27E1.mp4',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page27/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page27/E1/2.jpg'},
        { url: 'img/FriendsPlus/Page27/E1/3.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/tieude.e1.p27.mp3' },
        { url: 'img/FriendsPlus/Page27/E1/4.jpg',videoUrl:'Videos/P27E1.mp4' },
        { url: 'img/FriendsPlus/Page27/E1/5.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page27/E1/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page27/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anh1.e3.p27.mp3'},
        { url: 'img/FriendsPlus/Page27/E1/8.jpg' , audioUrl: 'img/FriendsPlus/Page27/Audio/anh2.e3.p27.mp3'},
      ],
      [
        { url: 'img/FriendsPlus/Page27/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anh3.e3.p27.mp3'},
        { url: 'img/FriendsPlus/Page27/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anh4.e3.p27.mp3' },
      ],
    ],
  },

  2: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P27-E2',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page27/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page27/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anh1.e3.p27.mp3'},
        { url: 'img/FriendsPlus/Page27/E1/8.jpg' , audioUrl: 'img/FriendsPlus/Page27/Audio/anh1.e3.p27.mp3'},
      ],
      [
        { url: 'img/FriendsPlus/Page27/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anh1.e3.p27.mp3'},
        { url: 'img/FriendsPlus/Page27/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anh1.e3.p27.mp3' },
      ],
    ],
  },

  3: { // Exercise num
    unit : 'Unit 3',
    id : 'SB2-U3-P27-E3',
    audio: '',
    video: '',
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit : true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page27/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page27/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page27/E3/3.jpg' , audioUrl: 'img/FriendsPlus/Page27/Audio/tieude.e3.p47.mp3' },
        { url: 'img/FriendsPlus/Page27/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page27/E3/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page27/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anh1.e3.p27.mp3'},
        { url: 'img/FriendsPlus/Page27/E1/8.jpg' , audioUrl: 'img/FriendsPlus/Page27/Audio/anh1.e3.p27.mp3'},
      ],
      [
        { url: 'img/FriendsPlus/Page27/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anh1.e3.p27.mp3'},
        { url: 'img/FriendsPlus/Page27/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page27/Audio/anh1.e3.p27.mp3' },
      ],
    ],
  },

}

export default json;

import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB2-U4-P32-E1',
    audio: 'img/FriendsPlus/Page32/E1/Audio/audio.e1.p32.mp3',
    video: '',
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page32/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page32/E1/Audio/tieude.e1.p32.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/4.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page32/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page32/E1/Audio/W.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page32/E1/Audio/woman.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page32/E1/Audio/wall.mp3' },
      ],
      [

        { url: 'img/FriendsPlus/Page32/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page32/E1/Audio/X.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page32/E1/Audio/box.mp3' },
        { url: 'img/FriendsPlus/Page32/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page32/E1/Audio/fox.mp3' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB2-U4-P32-E2',
    audio: 'img/FriendsPlus/Page32/E2/Audio/audio.e2.p32.mp3',
    video: '',
    isAllowSubmit: true,
    songLyric:
      "<p>What's this? What's this? </p>" +
      "<p>/w/ /w/ /w/ </p>" +
      "<p>It's a wall, a wall </p>" +
      "<p>A woman by a wall </p>" +
      "<p>What's this? What's this? </p>" +
      "<p>/ks/ /ks/ /ks/ </p>" +
      "<p>It's a box, a box, a fox in a box </p>" +
      "<p>What's this? What's this? </p>" +
      "<p>/w/ /w/ /w/ </p>" +
      "<p>It's a wall, a wall </p>" +
      "<p>A woman by a wall </p>" +
      "<p>What's this? What's this? </p>" +
      "<p>/ks/ /ks/ /ks/ </p>" +
      "<p>It's a box, a box, a fox in a box </p>",
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page32/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page32/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page32/E2/Audio/tieude.e2.p32.mp3' },
        { url: 'img/FriendsPlus/Page32/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page32/E2/Audio/audio.e2.p32.mp3' },

      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB2-U4-P32-E3',
    audio: '',
    isAllowSubmit: true,
    video: '',
    component: UnderLine,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page32/E3/1.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page32/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/3.jpg', input: 1, audioUrl: 'img/FriendsPlus/Page32/E1/Audio/W.mp3' },
        { url: 'img/FriendsPlus/Page32/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/5.jpg', input: 2, audioUrl: 'img/FriendsPlus/Page32/E1/Audio/X.mp3' },
        { url: 'img/FriendsPlus/Page32/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page32/E3/7.jpg', input: 3, audioUrl: 'img/FriendsPlus/Page32/E1/Audio/V.mp3' },
        { url: 'img/FriendsPlus/Page32/E3/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page32/E3/9.jpg' },
      ],


    ],
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB2-U4-P32-E4',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: 'img/FriendsPlus/Page32/E4/Key/answerKeyP32E4.jpg',
    recorder: false,
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page32/E4/1.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page32/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page32/E4/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page32/E4/4.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page32/E4/5.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page32/E4/6.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page32/E4/7.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page32/E4/8.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page32/E4/9.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page32/E4/10.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page32/E4/11.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page32/E4/12.jpg', input: 10 },
        { url: 'img/FriendsPlus/Page32/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page32/E4/14.jpg', input: 11 },
        { url: 'img/FriendsPlus/Page32/E4/15.jpg', input: 12 },
        { url: 'img/FriendsPlus/Page32/E4/16.jpg', input: 13 },
        { url: 'img/FriendsPlus/Page32/E4/17.jpg', input: 14 },
        { url: 'img/FriendsPlus/Page32/E4/18.jpg', input: 15, isCorrect: true },
        { url: 'img/FriendsPlus/Page32/E4/19.jpg', input: 16 },
        { url: 'img/FriendsPlus/Page32/E4/20.jpg', input: 17 },
        { url: 'img/FriendsPlus/Page32/E4/21.jpg', input: 18 },
        { url: 'img/FriendsPlus/Page32/E4/22.jpg', },

      ],
      [
        { url: 'img/FriendsPlus/Page32/E4/23.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page32/E4/24.jpg' },
        { url: 'img/FriendsPlus/Page32/E4/25.jpg', input: 19, isCorrect: true },
        { url: 'img/FriendsPlus/Page32/E4/26.jpg', input: 20 },
        { url: 'img/FriendsPlus/Page32/E4/27.jpg', input: 21 },
        { url: 'img/FriendsPlus/Page32/E4/28.jpg', input: 22 },
        { url: 'img/FriendsPlus/Page32/E4/29.jpg', input: 23 },
        { url: 'img/FriendsPlus/Page32/E4/30.jpg', input: 24 },
        { url: 'img/FriendsPlus/Page32/E4/31.jpg', input: 25 },
        { url: 'img/FriendsPlus/Page32/E4/32.jpg', input: 26 },
        { url: 'img/FriendsPlus/Page32/E4/33.jpg', input: 27 },
        { url: 'img/FriendsPlus/Page32/E4/34.jpg' },
        { url: 'img/FriendsPlus/Page32/E4/35.jpg', input: 28 },
        { url: 'img/FriendsPlus/Page32/E4/36.jpg', input: 29 },
        { url: 'img/FriendsPlus/Page32/E4/37.jpg', input: 30 },
        { url: 'img/FriendsPlus/Page32/E4/38.jpg', input: 31 },
        { url: 'img/FriendsPlus/Page32/E4/39.jpg', input: 32 },
        { url: 'img/FriendsPlus/Page32/E4/40.jpg', input: 33 },
        { url: 'img/FriendsPlus/Page32/E4/41.jpg', input: 34, isCorrect: true },
        { url: 'img/FriendsPlus/Page32/E4/42.jpg', },
        { url: 'img/FriendsPlus/Page32/E4/43.jpg', input: 35 },
        { url: 'img/FriendsPlus/Page32/E4/44.jpg', input: 36 },
        { url: 'img/FriendsPlus/Page32/E4/45.jpg', input: 37 },
        { url: 'img/FriendsPlus/Page32/E4/46.jpg', input: 38 },
        { url: 'img/FriendsPlus/Page32/E4/47.jpg', input: 39 },
        { url: 'img/FriendsPlus/Page32/E4/48.jpg', input: 40 },
        { url: 'img/FriendsPlus/Page32/E4/49.jpg', input: 41, isCorrect: true },
        { url: 'img/FriendsPlus/Page32/E4/50.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page32/E4/51.jpg' },
      ],

    ],
  },


}

export default json;